const contractsTemplatesData = [
  {
    id: 1,
    title: "SOW",
    text: "Lorem ipsum",
    author: "Orrick Law",
  },
  {
    id: 2,
    title: "Consultant Agreement",
    text: "Lorem ipsum",
    author: "Orrick LawB",
  },
  {
    id: 3,
    title: "NDA",
    text: "Your free NDA. Send money as many as you like",
    author: "Orrick Law",
  },
]
export default contractsTemplatesData
